import { Box, Flex, Image, Text } from "@chakra-ui/react";
import mttrail from "../../assets/springs_trail.jpg"

export default function About() {
  return (
    <Box p={2} pt={4} position="relative" minHeight="100vh">
      <Flex
        alignItems="center"
        p={4}
        height="100%"
        position="absolute"
        top="50%"
        left="32%"
        transform="translate(-35%, -50%)"
        flexDirection={"column"}
      >
        <Image
          src={mttrail}
          alt="about_placeholder"
          boxSize={["100px", "150px", "200px", "250px"]}
          objectFit="cover"
          borderRadius="full"
          ml={0}
          mr={4}
        />
        <Flex direction="column" justifyContent={"space-between"} mt={3}>
          <Text fontSize={"lg"}>
            <b>As an Individual...</b>
            <p>
              Originally from the Northeast, I am now located in Denver
              Colorado. As an avid cyclist and outdoor enthusiast, the Colorado
              Rockies were the perfect place to enjoy nature. In addition to my
              outdoor activities, I also enjoy working on cars, tinkering with
              my Raspberry Pi and Arduino and traveling.
            </p>
          </Text>
          <Text fontSize={"lg"} mt={3}>
            <b>As a Full Stack Engineer...</b>
            <p>
              With a passion for problem solving, I initially started my career
              in Tech as a Systems Engineer, specializing in data center
              networking and then in application and web hosting. Continuously
              moving towards automation and scripting, I transitioned into Cloud
              DevOps and Architecture. After spending several years honing and
              applying my DevOps skills, I finally decided to make the
              transition to software engineering. This transition has been by
              far, the most rewarding. It has allowed me to merge my existing
              infrastructure, DevOps, Cloud skills with an endless world of
              technologies. Originally gravitating toward backend, I have now
              expanded to frontend and AI driven applications.
            </p>
          </Text>
          <Text fontSize={"lg"} mt={3}>
            <b>As a Leader...</b>
            <p>
              While I may come with a versatile tool belt and a passion for
              problem solving, I am not always the smartest person in the room. That
              is especially true when the room is full of passionate engineering
              minds like myself. When leading a team, my goal is to not only
              deliver balanced solutions but also to elevate my team. Every team
              member brings insight and experience to the table and it's my job
              as a leader to provide the platform upon which they can be heard
              and deliver their best work.
            </p>
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
}
