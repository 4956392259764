import { Box, Flex, Image, Text } from "@chakra-ui/react";
import mountain from "../../assets/mountain.jpg";

export default function Home() {
  return (
    <Flex direction="column" alignItems="center" mt={3} justifyContent={"space-between"}>
      <Image
        src={mountain}
        alt="mountain"
        boxSize={["100px", "150px", "200px", "250px"]}
        objectFit="cover"
        borderRadius="full"
        mb={4}
      />
      <Box textAlign={"center"}>
        <Text justifyItems={"center"} fontSize={"lg"} fontWeight={"bold"}>
          This content is not quite ready yet.
        </Text>
        <Text justifyItems={"center"} fontSize={"sm"} fontWeight={"bold"}>
          I promise it's coming soon!
        </Text>
      </Box>
    </Flex>
  );
}
