import { Box, Flex, Image, Text } from "@chakra-ui/react";
import self_small from "../../assets/self_round_cycle.jpg";

export default function Contact() {
  return (
    <Box p={2} pt={4} position="relative" minHeight="80vh">
      <Flex
        alignItems="center"
        justifyItems="center"
        p={4}
        height="100%"
        justifyContent="center"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        {/* <Image
          src={self_small}
          alt="self_small"
          boxSize={200}
          objectFit="cover"
          borderRadius="full"
          ml={0}
          mr={4}
        />
        <Flex direction="column">
          <Text fontSize={"3xl"} as="b">
            Hi! I'm Nick.
          </Text>
          <Text fontSize={"md"}>
            Senior Software Engineer & Technical Leader
          </Text>
          <Text fontSize={"sm"}>Denver, CO</Text>
        </Flex> */}
        <Text>Contact</Text>
      </Flex>
    </Box>
  );
}
