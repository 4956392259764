import { Route, Routes } from "react-router-dom";
import NotFound from "../../pages/not-found/NotFound";
import MainLayout from "../main-layout/MainLayout";
import About from "../../pages/about/About";
import Contact from "../../pages/contact/Contact";
import Projects from "../../pages/projects/Projects";

export default function AppRouter() {
  return (
    <Routes>
      {/* <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="services" element={<Services />} />
        <Route path="contact" element={<Contact />} /> */}
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<MainLayout />}>
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="projects" element={<Projects />} />
      </Route>
    </Routes>
  );
}
